import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from "./DownloadCardAsp.module.css";
import {
  Card,
  CardActionArea,
  Container,
  CardMedia,
  CardContent,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import request from "../../utils/request";
import t from "../../utils/translation";
import { getDist } from "../../utils/sessionHandler";
import Redirect from "../../utils/Redirect";
import FixedAlert from "../FixedAlert/FixedAlert";
import Progress from "../Progress";
import SmCloudImg from "../../assets/images/SM CLOUD.svg";
import ASPImg from "../../assets/images/ASP TM CHICO.svg";

const DownloadManagerAsp = ({
  apiMethod,
  translations,
  allowGoToMainPage,
  prefixTranslations,
}) => {
  const theme = useTheme();
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState();

  const downloadFile = async () => {
    if (!downloading) {
      setDownloading(true);
      request()
        .get(
          `/api/DownloaderManager/${apiMethod}?distributorId=${getDist().id}`
        )
        .then((resp) => {
          window.open(resp.data, "_blank");
          setTimeout(() => {
            setDownloading(false);
          }, 10000);
        })
        .catch((err) => {
          setError(err?.response?.data?.Message);
        });
    }
  };

  const goMainPage = () => {
    Redirect({
      redirect: true,
      path: "/login",
    });
  };

  return (
    <div className={styles.container}>
      <FixedAlert
        text={error}
        type={"error"}
        closeErrorCb={() => {
          setError("");
        }}
      />
      <p className={styles.label}>
        {t(`${prefixTranslations}Label`, translations) || "Agent"}
      </p>
      <div className={styles.cardContainer}>
        <Card className={styles.root}>
          <Progress active={downloading} />
          <CardActionArea onClick={downloadFile}>
            <img className={styles.media} src={ASPImg} alt="asp-img" />
            <div>
              <div className={styles.textContainer}>
                <div>
                  <img
                    className={styles.textImage}
                    src={SmCloudImg}
                    alt="smcloud-img"
                  />
                </div>
                <p className={styles.textContainerLabel}>
                  {t(`${prefixTranslations}Label`, translations) || "Agent"}
                </p>
              </div>
              {downloading && (
                <>
                  <CircularProgress />
                  <div>
                    {t("Downloading", translations) || "Downloading..."}
                  </div>
                </>
              )}
            </div>
          </CardActionArea>
          <button className={styles.downloadButton} onClick={downloadFile}>
            <span>{t("Download") || "Download"}</span>
            <GetAppIcon style={{fontSize: "1.125rem"}}/>
          </button>
          {allowGoToMainPage && (
            <Button color="primary" onClick={goMainPage}>
              Go To Main Page
            </Button>
          )}
        </Card>
      </div>
    </div>
  );
};

export default DownloadManagerAsp;
