import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import t from "../../utils/translation";
import DownloadManager from "./DownloadManager";
import DownloadManagerAsp from "./DownloadManagerAsp";

const useStylesAccordion = makeStyles((theme) => ({
  root: {
    marginTop: "25px",
    width: "100%",
    color: theme.palette.terragene.lightMain,
    fontSize: "10px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    background: theme.palette.terragene.darkSecondary,
  },
}));
const useStylesAccordionSummary = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    minHeight: "35px",
  },
  expandIcon: {
    color: theme.palette.terragene.lightThird,
  },
}));
const useStylesChip = makeStyles((theme) => ({
  root: {
    margin: "5px",
    color: theme.palette.terragene.darkMain,
    backgroundColor: theme.palette.terragene.lightThird,
  },
  deleteIcon: {
    color: theme.palette.terragene.darkMain,
  },
}));
const useStylesTypography = makeStyles((theme) => ({
  root: {
    fontSize: "13px",
    color: theme.palette.terragene.lightThird,
  },
}));
const useStylesAccordionDetail = makeStyles(() => ({
  root: {
    padding: "0px 6px 26px",
  },
}));

const theme = JSON.parse(localStorage.getItem("__dist"))?.themeFileName;

const AccordionDownloader = ({ translations }) => {
  const classesAccordion = useStylesAccordion();
  const classesAccordionSummary = useStylesAccordionSummary();
  const classesChip = useStylesChip();
  const classesTypographyTitle = useStylesTypography();
  const classesAccordionDetail = useStylesAccordionDetail();
  return (
    <Accordion classes={classesAccordion}>
      <AccordionSummary
        classes={classesAccordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography classes={classesTypographyTitle}>
          {t("DownloadAgent")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={classesAccordionDetail}>
        {theme !== "aspTheme" ? (
          <>
            <DownloadManager
              apiMethod={"SASUri"}
              prefixTranslations={"Agent"}
              translations={translations}
            ></DownloadManager>
            <DownloadManager
              apiMethod={"SASUriBionovaCloudManual"}
              prefixTranslations={"Manual"}
              translations={translations}
            ></DownloadManager>

            <DownloadManager
              apiMethod={"SASUriMigrationManual"}
              prefixTranslations={"ManualMigration"}
              translations={translations}
            ></DownloadManager>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              padding: "0 15px ",
            }}
          >
            <DownloadManagerAsp
              apiMethod={"SASUri"}
              prefixTranslations={"Agent"}
              translations={translations}
            ></DownloadManagerAsp>
            <DownloadManagerAsp
              apiMethod={"SASUriSMCloudManual"}
              prefixTranslations={"ASPManual"}
              translations={translations}
            ></DownloadManagerAsp>

            <DownloadManagerAsp
              apiMethod={"SASUriASPUpdater"}
              prefixTranslations={"ASPUpdater"}
              translations={translations}
            ></DownloadManagerAsp>
            <DownloadManagerAsp
              apiMethod={"SASUriSMCloudCyberSecurity"}
              prefixTranslations={"ASPCyberSecurity"}
              translations={translations}
            ></DownloadManagerAsp>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionDownloader;
