import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useMemo,
  useRef,
} from "react";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import _ from "lodash";
import {
  getSession,
  getUserId,
  getUserCompany,
  getUserType,
  getDist,
} from "../../utils/sessionHandler";
import useStyles from "./ProFinishedStyles";
import { flatten } from "flat";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { unflatten } from "flat";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Modal from "../../components/Modal";
import CircularProgress from "../../components/CircularProgress";
import Progress from "../../components/Progress";
import Button from "../../components/Button";
import { IndicatorType } from "../../dictionaries/indicatorTypes";
import QuickWasher from "../../components/QuickWasher/QuickWasher";
import GroupedDetail from "../../components/GroupedDetail/GroupedDetail";
import reducer from "./ProFinishedReducer";
import { WorkingMode } from "../../dictionaries/workingMode";
import ToolMeasure from "../../components/ToolMeasure/ToolMeasure";
import SplitButton from "../../components/SplitButton/SplitButton";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { ProductType } from "../../dictionaries/productType";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const formatSelectObjectWas = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([
        object.id,
        object.description || object.toString,
        object.sectorId,
      ]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = {
  programs: [],
  products: [],
  washers: [],
  visualResults: [],
  loadingContent: true,
  showToolMeasureComponent: false,
  readingPROToAssociate: {},
  afterAssociate: {
    close: false,
    message: "",
  },
};

const ProFinished = () => {
  const classes = useStyles();
  const locale = useContext(LocalizationContext);
  const CRUDRef = useRef(null);
  const groups = [
    { Description: t("ProductGroup"), position: 1, Index: 0, visible: true },
    { Description: t("ProcessGroup"), position: 2, Index: 1, visible: true },
    { Description: t("OtherGroup"), position: 3, Index: 2, visible: true },
    { Description: t("IncubationGroup"), position: 0, Index: 3, visible: true },
  ];
  const indexProductGroup = groups[0].Index;
  const indexProcessGroup = groups[1].Index;
  const indexOtherGroup = groups[2].Index;
  const indexIncubationGroup = groups[3].Index;

  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailObject, setDetailObject] = useState(null);
  const [toHistoricalObject, setToHistoricalObject] = useState(null);
  const [toHistoricalLoading, setToHistoricalLoading] = useState(false);
  const [toHistoricalMessage, setToHistoricalMessage] = useState({
    title: t("CantSave"),
    type: "none",
  });
  const [errors, setErrors] = useState([]);
  const [splitOptions, setSplitOptions] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    programs,
    products,
    washers,
    visualResults,
    loadingContent,
    requiredFields,
  } = state;

  const formFields = useMemo(
    () =>
      selectedProduct == "PRO1ENDO"
        ? [
            {
              position: 0,
              groupIndex: 0,
              label: t("ProductLabel", locale),
              propOnObject: "ReadingPRO.productId",
              propOnMassiveObject: "ReadProMasive.productId",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: products,
              onSelectedChange: (row) => {
                {
                  handleProduct(row);
                }
              },
              required: requiredFields?.find((x) => x.objectName === 1531)
                ? true
                : false,
            },
            {
              position: 1,
              groupIndex: 0,
              label: t("LotLabel", locale),
              propOnObject: "ReadingPRO.loteProduct",
              propOnMassiveObject: "ReadProMasive.loteProduct",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1545)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("BookNumberLabel", locale),
              propOnObject: "ReadingPRO.bookNumber",
              propOnMassiveObject: "ReadProMasive.bookNumber",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1555)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("PageNumberLabel", locale),
              propOnObject: "ReadingPRO.pageNumber",
              propOnMassiveObject: "ReadProMasive.pageNumber",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1565)
                ? true
                : false,
            },
            {
              position: 1,
              groupIndex: indexProcessGroup,
              label: t("CycleLabel", locale),
              propOnObject: "ReadingPRO.cycle",
              propOnMassiveObject: "ReadProMasive.cycle",
              class: "col-12 col-md-6 px-0",
              type: "specialInput",
              required: requiredFields?.find((x) => x.objectName === 1575)
                ? true
                : false,
            },
            {
              position: 2,
              groupIndex: indexProcessGroup,
              label: t("ProgramNumberLabel", locale),
              propOnObject: "ReadingPRO.programNumber",
              propOnMassiveObject: "ReadProMasive.programNumber",
              class: "col-12 col-md-6 px-0",
            },
            {
              position: 4,
              groupIndex: indexProcessGroup,
              label: t("Length(cm)", locale) || "Length (cm)",
              propOnObject: "ReadingPRO.longitud",
              propOnMassiveObject: "ReadProMasive.longitud",
              class: "col-12 col-md-6 px-0",
            },
            {
              position: 5,
              groupIndex: indexProcessGroup,
              label: t("Diameter(cm)", locale) || "Diameter (cm)",
              propOnObject: "ReadingPRO.diamtero",
              propOnMassiveObject: "ReadProMasive.diametro",
              class: "col-12 col-md-6 px-0",
            },
            {
              position: 3,
              groupIndex: indexProcessGroup,
              label: t("ToolLabel", locale),
              visibleCondition: ["ReadingPRO.modeId", "=", "0"],
              propOnObject: "ReadingPRO.tool",
              propOnMassiveObject: "ReadProMasive.tool",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1610)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: indexProcessGroup,
              label: t("WasherLabel", locale),
              propOnObject: "ReadingPRO.washerId",
              propOnMassiveObject: "ReadProMasive.washerId",
              component: (
                <a
                  style={{ fontSize: 11 }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: "SHOW_ABM_WASHER" });
                  }}
                  href="#"
                >
                  {t("AddWasher", locale)}
                </a>
              ),
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: washers,
              id: "machine",
              userType: getUserType(),
              defaultValue: state.selectedWasher,
              required: requiredFields?.find((x) => x.objectName === 1595)
                ? true
                : false,
            },
            {
              position: 2,
              groupIndex: 0,
              label: t("VisualResultLabel", locale),
              propOnObject: "ReadingPRO.visualResult",
              propOnMassiveObject: "ReadProMasive.visualResult",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: visualResults,
              required: requiredFields?.find((x) => x.objectName === 1636)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("IgnoreFromIQAS", locale),
              propOnObject: "ReadingPRO.ignoreFromIQAS",
              propOnMassiveObject: "ReadProMasive.ignoreFromIQAS",
              class: "col-12 col-md-6 px-0",
              type: "checkbox",
              required: false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("NotesLabel", locale),
              propOnObject: "ReadingPRO.notes",
              propOnMassiveObject: "ReadProMasive.notes",
              class: "col-12 px-0",
              required: requiredFields?.find((x) => x.objectName === 1585)
                ? true
                : false,
            },
          ]
        : [
            {
              position: 0,
              groupIndex: 0,
              label: t("ProductLabel", locale),
              propOnObject: "ReadingPRO.productId",
              propOnMassiveObject: "ReadProMasive.productId",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: products,
              onSelectedChange: (row) => {
                {
                  handleProduct(row);
                }
              },
              required: requiredFields?.find((x) => x.objectName === 1531)
                ? true
                : false,
            },
            {
              position: 1,
              groupIndex: 0,
              label: t("LotLabel", locale),
              propOnObject: "ReadingPRO.loteProduct",
              propOnMassiveObject: "ReadProMasive.loteProduct",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1545)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("BookNumberLabel", locale),
              propOnObject: "ReadingPRO.bookNumber",
              propOnMassiveObject: "ReadProMasive.bookNumber",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1555)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("PageNumberLabel", locale),
              propOnObject: "ReadingPRO.pageNumber",
              propOnMassiveObject: "ReadProMasive.pageNumber",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1565)
                ? true
                : false,
            },
            {
              position: 1,
              groupIndex: indexProcessGroup,
              label: t("CycleLabel", locale),
              propOnObject: "ReadingPRO.cycle",
              propOnMassiveObject: "ReadProMasive.cycle",
              class: "col-12 col-md-6 px-0",
              type: "specialInput",
              required: requiredFields?.find((x) => x.objectName === 1575)
                ? true
                : false,
            },
            {
              position: 2,
              groupIndex: indexProcessGroup,
              label: t("ProgramNumberLabel", locale),
              propOnObject: "ReadingPRO.programNumber",
              propOnMassiveObject: "ReadProMasive.programNumber",
              class: "col-12 col-md-6 px-0",
            },
            {
              position: 3,
              groupIndex: indexProcessGroup,
              label: t("ToolLabel", locale),
              visibleCondition: ["ReadingPRO.modeId", "=", "0"],
              propOnObject: "ReadingPRO.tool",
              propOnMassiveObject: "ReadProMasive.tool",
              class: "col-12 col-md-6 px-0",
              required: requiredFields?.find((x) => x.objectName === 1610)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: indexProcessGroup,
              label: t("WasherLabel", locale),
              propOnObject: "ReadingPRO.washerId",
              propOnMassiveObject: "ReadProMasive.washerId",
              component: (
                <a
                  style={{ fontSize: 11 }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: "SHOW_ABM_WASHER" });
                  }}
                  href="#"
                >
                  {t("AddWasher", locale)}
                </a>
              ),
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: washers,
              id: "machine",
              userType: getUserType(),
              defaultValue: state.selectedWasher,
              required: requiredFields?.find((x) => x.objectName === 1595)
                ? true
                : false,
            },
            {
              position: 2,
              groupIndex: 0,
              label: t("VisualResultLabel", locale),
              propOnObject: "ReadingPRO.visualResult",
              propOnMassiveObject: "ReadProMasive.visualResult",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: visualResults,
              required: requiredFields?.find((x) => x.objectName === 1636)
                ? true
                : false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("IgnoreFromIQAS", locale),
              propOnObject: "ReadingPRO.ignoreFromIQAS",
              propOnMassiveObject: "ReadProMasive.ignoreFromIQAS",
              class: "col-12 col-md-6 px-0",
              type: "checkbox",
              required: false,
            },
            {
              position: 0,
              groupIndex: 2,
              label: t("NotesLabel", locale),
              propOnObject: "ReadingPRO.notes",
              propOnMassiveObject: "ReadProMasive.notes",
              class: "col-12 px-0",
              required: requiredFields?.find((x) => x.objectName === 1585)
                ? true
                : false,
            },
          ],
    [selectedProduct]
  );
  const detailFields = useMemo(
    () =>
      selectedProduct === "PRO1ENDO"
        ? [
            //process group
            {
              label: t("WasherLabel", locale),
              name: "washer.toString",
              position: 0,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("WasherLabelBrandLabel", locale),
              name: "washer.brand",
              position: 1,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("WasherSerialNumberLabel", locale),
              name: "washer.serial",
              position: 2,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("CycleLabel", locale),
              name: "cycle",
              position: 3,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("ProgramNumberLabel", locale),
              name: "programNumber",
              position: 6,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("Length(cm)", locale) || "Length (cm)",
              name: "length",
              position: 7,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("Diameter(cm)", locale) || "Diameter (cm)",
              name: "diameter",
              position: 8,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("Density", locale) || "Density",
              name: "density",
              position: 9,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("AreaLabel", locale),
              name: "instrumentMeasure.surfaceFormat",
              position: 4,
              valueVisibilityField: "modeId",
              groupIndex: indexProcessGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("ToolLabel", locale),
              name: "tool",
              position: 5,
              groupIndex: indexProcessGroup,
            },
            //product group
            {
              label: t("ProductLabel", locale),
              name: "product.toString",
              position: 0,
              groupIndex: indexProductGroup,
            },
            {
              label: t("ProductBrandLabel", locale),
              name: "product.brand",
              position: 1,
              groupIndex: indexProductGroup,
            },
            {
              label: t("LotLabel", locale),
              name: "loteProduct",
              position: 2,
              groupIndex: indexProductGroup,
            },
            {
              label: t("VisualResultLabel", locale),
              name: "visualResultDescription",
              position: 3,
              groupIndex: indexProductGroup,
            },
            //incubation group
            {
              label: t("PositionNumberLabel", locale),
              name: "positionNumber",
              position: 0,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("TicketNumber", locale),
              name: "ticketNumber",
              position: 1,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("StartedTimeLabel", locale),
              name: "startedTime",
              type: "dateTime",
              position: 2,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("EndTimeLabel", locale),
              name: "resultDate",
              type: "dateTime",
              position: 3,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("AverageTemperatureLabel", locale),
              name: "averageTemperatureProFormat",
              position: 4,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("IncubatorLabel", locale),
              name: "incubator.toString",
              position: 5,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ProgramLabel", locale),
              name: "program.nombre",
              position: 6,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ResultLabel", locale),
              name: "resultDescription",
              position: 7,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ProteinLabel", locale),
              name: "proteinFormat",
              position: 8,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: true },
                { condition: WorkingMode.ISO, visible: false },
              ],
            },
            {
              label: t("ProteinAlertLabel", locale),
              name: "alertResult",
              position: 9,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("ProteinActionLabel", locale),
              name: "actionResult",
              position: 10,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("CuantitativeResultLabel", locale),
              name: "cuantitativeResultFormat",
              position: 11,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("UserLabel", locale),
              name: "appUser.toString",
              position: 12,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("SectorLabel", locale),
              name: "sector.toString",
              position: 13,
              groupIndex: indexIncubationGroup,
            },
            //other group
            {
              label: t("BookNumberLabel", locale),
              name: "bookNumber",
              position: 0,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("PageNumberLabel", locale),
              name: "pageNumber",
              position: 1,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("NotesLabel", locale),
              name: "notes",
              position: 2,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("IgnoreFromIQAS", locale),
              name: "ignoreFromIQAS",
              position: 3,
              groupIndex: indexOtherGroup,
            },
          ]
        : [
            //process group
            {
              label: t("WasherLabel", locale),
              name: "washer.toString",
              position: 0,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("WasherLabelBrandLabel", locale),
              name: "washer.brand",
              position: 1,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("WasherSerialNumberLabel", locale),
              name: "washer.serial",
              position: 2,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("CycleLabel", locale),
              name: "cycle",
              position: 3,
              groupIndex: indexProcessGroup,
            },
            {
              label: t("ProgramNumberLabel", locale),
              name: "programNumber",
              position: 6,
              groupIndex: indexProcessGroup,
            },

            {
              label: t("AreaLabel", locale),
              name: "instrumentMeasure.surfaceFormat",
              position: 4,
              valueVisibilityField: "modeId",
              groupIndex: indexProcessGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("ToolLabel", locale),
              name: "tool",
              position: 5,
              groupIndex: indexProcessGroup,
            },
            //product group
            {
              label: t("ProductLabel", locale),
              name: "product.toString",
              position: 0,
              groupIndex: indexProductGroup,
            },
            {
              label: t("ProductBrandLabel", locale),
              name: "product.brand",
              position: 1,
              groupIndex: indexProductGroup,
            },
            {
              label: t("LotLabel", locale),
              name: "loteProduct",
              position: 2,
              groupIndex: indexProductGroup,
            },
            {
              label: t("VisualResultLabel", locale),
              name: "visualResultDescription",
              position: 3,
              groupIndex: indexProductGroup,
            },
            //incubation group
            {
              label: t("PositionNumberLabel", locale),
              name: "positionNumber",
              position: 0,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("TicketNumber", locale),
              name: "ticketNumber",
              position: 1,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("StartedTimeLabel", locale),
              name: "startedTime",
              type: "dateTime",
              position: 2,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("EndTimeLabel", locale),
              name: "resultDate",
              type: "dateTime",
              position: 3,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("AverageTemperatureLabel", locale),
              name: "averageTemperatureProFormat",
              position: 4,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("IncubatorLabel", locale),
              name: "incubator.toString",
              position: 5,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ProgramLabel", locale),
              name: "program.nombre",
              position: 6,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ResultLabel", locale),
              name: "resultDescription",
              position: 7,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("ProteinLabel", locale),
              name: "proteinFormat",
              position: 8,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: true },
                { condition: WorkingMode.ISO, visible: false },
              ],
            },
            {
              label: t("ProteinAlertLabel", locale),
              name: "alertResult",
              position: 9,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("ProteinActionLabel", locale),
              name: "actionResult",
              position: 10,
              valueVisibilityField: "modeId",
              groupIndex: indexIncubationGroup,
              visibilityFieldDependencies: [
                { condition: WorkingMode.HTM01, visible: false },
                { condition: WorkingMode.ISO, visible: true },
              ],
            },
            {
              label: t("CuantitativeResultLabel", locale),
              name: "cuantitativeResultFormat",
              position: 11,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("UserLabel", locale),
              name: "appUser.toString",
              position: 12,
              groupIndex: indexIncubationGroup,
            },
            {
              label: t("SectorLabel", locale),
              name: "sector.toString",
              position: 13,
              groupIndex: indexIncubationGroup,
            },
            //other group
            {
              label: t("BookNumberLabel", locale),
              name: "bookNumber",
              position: 0,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("PageNumberLabel", locale),
              name: "pageNumber",
              position: 1,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("NotesLabel", locale),
              name: "notes",
              position: 2,
              groupIndex: indexOtherGroup,
            },
            {
              label: t("IgnoreFromIQAS", locale),
              name: "ignoreFromIQAS",
              position: 3,
              groupIndex: indexOtherGroup,
            },
          ],
    [locale, selectedProduct]
  );
  const handleProduct = (value) => {
    let selectedProduct = products.find((p) => p[0] == value);

    setSelectedProduct(selectedProduct[1]);
  };

  useEffect(() => {
    Promise.all([
      request().get("/api/Program"),
      request().get(
        `/api/Product/GetByDistributorEncrypted?distributorId=${getDist().id}`
      ),
      request().get(`/api/washer?CompanyId=${getUserCompany().Id}`),
      request().get(`/api/Enum/GetVisualResultsPRO`),
      request().get(`/api/Screen/GetRequiredFields?Screen=500`),
    ])
      .then(
        ([
          programsData,
          productsData,
          washersData,
          visualResults,
          requiredFields,
        ]) => {
          setTimeout(() => {
            dispatch({
              type: "SET_CONTENT",
              payload: {
                requiredFields: requiredFields.data,
                programs: formatSelectObject(programsData.data, ""),
                products: formatSelectObject(
                  productsData.data.filter(
                    (p) => p.productModel.type === ProductType.Protein
                  ),
                  t("ChooseAProduct", locale)
                ),
                washers: formatSelectObjectWas(
                  washersData.data,
                  t("ChooseAWasher", locale)
                ),
                visualResults: formatSelectObject(
                  visualResults.data,
                  t("ChooseAVisualResult")
                ),
              },
            });
          }, 250);
        }
      )
      .catch((error) => console.log(error.response));
  }, [locale]);

  useEffect(() => {
    if (!loadingContent) {
      setLoadingData(true);
      updateData();
    }
  }, [loadingContent, programs]);

  const updateData = () => {
    request()
      .get(`/api/ReadingPRO/getprovisional?UserLoggedId=${getUserId()}`)
      .then((response) => {
        setData(
          response.data
            .reduce((readingsList, reading) => {
              let programNameFromArr = "";
              for (let i = 0; i < programs.length; i++) {
                if (programs[i][0] === reading.programId) {
                  programNameFromArr = programs[i][1];
                }
              }
              readingsList.push({
                ...reading,
                programName: programNameFromArr,
                startedTimeFormatted: df(reading.startedTime, "dateTime"),
                resultDateFormatted: df(reading.resultDate, "dateTime"),
                incubatorName: reading.incubator.toString,
              });
              return readingsList;
            }, [])
            .map((x) =>
              _.mapKeys(x, (value, key) => {
                return "ReadingPRO." + key;
              })
            )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  };

  const getDetail = (r) => {
    setLoadingDetail(true);
    request()
      .get(`/api/ReadingPRO/getDetail?Id=${r["ReadingPRO.id"]}`)
      .then((response) =>
        setTimeout(() => {
          const row = data.find((x) => x["ReadingPRO.id"] === response.data.id);
          if (
            row["ReadingPRO.modeId"] == WorkingMode.ISO &&
            row["ReadingPRO.instrumentMeasure"]
          ) {
            const instrumento = row["ReadingPRO.instrumentMeasure"].instrument;
            response.data.tool =
              instrumento.code + " - " + instrumento.description;
          }
          row["ReadingPRO.viewed"] = true;
          setData(data);
          setLoadingDetail(false);

          response.data.incubatorName = response.data.incubator.toString;
          const flattenObj = flatten(response.data);
          setDetailObject(flattenObj);
        }, 500)
      );
  };

  const toHistorical = (read) => {
    setToHistoricalLoading(true);
    request()
      .post("/api/ReadingPRO/toHistorical", {
        ...unflatten(read),
        UserLogged: getSession(),
      })
      .then((response) => {
        if (response.data.result === "ERRORS") {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "error",
              message: response.data.errors.map((e) => <p key={e}>{e}</p>),
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
        } else {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "success",
              message: response.data.message,
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
          setTimeout(() => {
            setToHistoricalMessage({ type: "none" });
          }, 3000);
        }
      })
      .catch((error) => {
        setToHistoricalObject(false);
        setToHistoricalLoading(false);
        setToHistoricalMessage({
          ...toHistoricalMessage,
          type: "error",
          message: error.response?.data?.message || error.response?.data,
        });
      });
  };

  const toHistoricalMassive = (readings, ids) => {
    setToHistoricalLoading(true);
    request()
      .post("/api/ReadingPRO/masiveToHistorical", {
        ReadPROMasive: { ReadIds: ids, ...unflatten(readings[0]) },
        UserLogged: getSession(),
      })
      .then((response) => {
        if (response.data.result === "ERRORS") {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "error",
              message: response.data.errors.map((e) => <p key={e}>{e}</p>),
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
        } else {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "success",
              message: response.data.message,
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
          setTimeout(() => {
            setToHistoricalMessage({ type: "none" });
          }, 3000);
        }
      })
      .catch((error) => {
        setToHistoricalObject(false);
        setToHistoricalLoading(false);
        setToHistoricalMessage({
          type: "error",
          message: error.response?.data?.message || error.response?.data,
        });
      });
  };

  const RequiredFieldMessage =
    t("RequiredFieldError") || "This field is required";

  const validateReading = (reading, formFields) => {
    let requiredError = formFields.reduce((errorBag, field) => {
      (_.get(reading, field.propOnObject) === undefined ||
        _.get(reading, field.propOnObject) === "" ||
        _.get(reading, field.propOnObject) === null) &&
        formFields.find((x) => x.propOnObject == field.propOnObject).required &&
        (errorBag[field.propOnObject] = RequiredFieldMessage);
      return errorBag;
    }, {});

    if (Object.keys(requiredError).length > 0) {
      setErrors(requiredError);
    } else {
      return true;
    }
  };

  const clearMessageValidation = () => {
    setErrors([]);
  };

  const showModalToolMeasure = (object, formFields) => {
    if (validateReading(object, formFields)) {
      setErrors([]);
      dispatch({
        type: "SET_READING_TO_ASSOCIATE",
        payload: { readingPROToAssociate: object },
      });
      dispatch({ type: "SHOW_TOOL_MEASURE" });
    }
  };

  const handleAfterAssociate = (serverResponse, updatedObject) => {
    dispatch({ type: "HIDE_TOOL_MEASURE" });
    setLoadingData(true);
    dispatch({
      type: "SET_AFTER_ASSOCIATE",
      payload: {
        afterAssociate: {
          executeAction: true,
          message: serverResponse.message,
          updatedObject: updatedObject,
          type: serverResponse.result,
        },
      },
    });
    dispatch({
      type: "SET_AFTER_ASSOCIATE",
      payload: { afterAssociate: { executeAction: false } },
    });
  };

  const closeModalToolMeasure = () => {
    dispatch({ type: "HIDE_TOOL_MEASURE" });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      {toHistoricalMessage.type === "success" && (
        <div className={`alert alert-${toHistoricalMessage.type}`} role="alert">
          {toHistoricalMessage.message}
        </div>
      )}
      <CRUD
        ref={CRUDRef}
        newOption={false}
        detailOption={false}
        deleteOption={false}
        multiSelect={true}
        massiveActions={[
          {
            caption: t("MultipleToHistoricalButton"),
            action: (a, b) =>
              setToHistoricalObject({
                object: a,
                action: () => toHistoricalMassive(a, b),
              }),
          },
        ]}
        onEditClick={(rowSelected) => {
          let product = rowSelected["ReadingPRO.productId"];
          handleProduct(product);
        }}
        resultStatus={true}
        editedInfo={{ IndicatorType: IndicatorType.Protein, enabled: true }}
        viewedMark={true}
        data={data}
        order={{ dir: "desc", orderBy: "ReadingPRO.readNumber" }}
        updateData={updateData}
        loadingData={loadingData}
        url={"/api/ReadingPRO"}
        massiveURL={"/api/ReadingPRO/masiveModify"}
        showModalToolMeasure={showModalToolMeasure}
        errorsFromValidation={errors}
        clearMessageValidation={clearMessageValidation}
        afterAssociate={state.afterAssociate}
        title={t("Title", locale)}
        tableHeader={t("ProvisionalTitle", locale)}
        tableActions={[
          {
            icon: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
            tooltip: t("DetailTooltip"),
            action: getDetail,
          },
          {
            icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
            tooltip: t("ToHistoricalTooltip"),
            action: (r) =>
              setToHistoricalObject({
                object: r,
                action: () => toHistorical(r),
              }),
          },
        ]}
        columns={[
          "ReadingPRO.readNumber",
          "ReadingPRO.positionNumber",
          "ReadingPRO.startedTimeFormatted",
          "ReadingPRO.resultDateFormatted",
          "ReadingPRO.resultDescription",
          "ReadingPRO.visualResultDescription",
          "ReadingPRO.cuantitativeResultFormat",
          "ReadingPRO.densidad",
          "ReadingPRO.ticketNumber",
          "ReadingPRO.incubatorName",
        ]}
        headers={[
          t("ReadNumberLabel", locale),
          t("PositionNumberLabel", locale),
          t("StartedTimeLabel", locale),
          t("ResultDateLabel", locale),
          t("ResultLabel", locale),
          t("VisualResultLabel", locale),
          t("CuantitativeResultLabel", locale),
          t("Density", locale) || "Density",

          t("TicketNumber", locale),
          t("IncubatorLabel", locale),
        ]}
        idField={"ReadingPRO.id"}
        splitButtonExtraOptions={[
          {
            text: t("ConfirmAndSave"),
            onClickOption: undefined,
            disabled: false,
            moveToSaved: true,
            useCommonFunction: true,
          },
        ]}
        grouped={groups}
        useFieldMap={false}
        formFields={formFields}
        formFieldsMachine={[washers]}
        baseObject={{
          ReadingPRO: {
            productId: null,
            loteProduct: null,
            bookNumber: null,
            pageNumber: null,
            cycle: null,
            washerId: null,
            visualResult: null,
            notes: null,
          },
          UserLogged: getSession(),
        }}
        massiveBaseObject={{
          ReadProMasive: {
            ReadIds: [],
            productId: null,
            loteProduct: null,
            bookNumber: null,
            pageNumber: null,
            cycle: null,
            washerId: null,
            visualResults: null,
            notes: null,
          },
          UserLogged: getSession(),
        }}
        method={"put"}
      />

      {(loadingDetail || detailObject) && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{`${t("Title", locale)} ${
                detailObject ? `(${detailObject.readNumber})` : ""
              }`}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => setDetailObject(null)}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => setDetailObject(null)}
          responsive
        >
          {!detailObject && (
            <div className={classes.detailLoading}>
              <CircularProgress />
            </div>
          )}
          {detailObject && (
            <GroupedDetail
              groups={groups}
              detailFields={detailFields}
              detailObject={detailObject}
            ></GroupedDetail>
          )}
        </Modal>
      )}

      {toHistoricalObject && (
        <Modal
          closeModal={() => setToHistoricalObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {t("ToHistoricalHeader", locale)}{" "}
                  {toHistoricalObject.object.length > 0
                    ? "(" +
                      toHistoricalObject.object.reduce((name, item, index) => {
                        return (
                          name +
                          item["ReadingPRO.toString"] +
                          (index === toHistoricalObject.object.length - 1
                            ? ""
                            : ", ")
                        );
                      }, "") +
                      ")"
                    : toHistoricalObject.object["ReadingPRO.toString"]}
                </span>
              </div>
              <Progress active={toHistoricalLoading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                onClick={() => setToHistoricalObject(null)}
                variant={"text"}
                color={"error"}
                disabled={toHistoricalLoading}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={toHistoricalObject.action}
                disabled={toHistoricalLoading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("ToHistoricalConfirmationText", locale)}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {toHistoricalMessage.type === "error" && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}></div>
              {toHistoricalMessage.title}
              <span
                className={classes.modalCloseButton}
                onClick={() => setToHistoricalMessage({ type: "none" })}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => setToHistoricalMessage({ type: "none" })}
        >
          <div className={`alert alert-danger`} role="alert">
            {toHistoricalMessage.message}
          </div>
        </Modal>
      )}
      {state.abmWasherVisible && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{t("AddWasher", locale)}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => dispatch({ type: "HIDE_ABM_WASHER" })}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => dispatch({ type: "HIDE_ABM_WASHER" })}
        >
          <QuickWasher
            onClickCallBack={(e) => {
              const newWasher = [e.Id, e.Name, getSession().SectorId];
              washers.push(newWasher);
              dispatch({
                type: "ADD_WASHER",
                payload: { Washers: washers, Selected: e.Id },
              });
            }}
          ></QuickWasher>
        </Modal>
      )}
      {state.showToolMeasureComponent && (
        <ToolMeasure
          readingPRO={state.readingPROToAssociate}
          method={"put"}
          url={"/api/ReadingPRO"}
          handleAfterAssociate={handleAfterAssociate}
          closeModalToolMeasure={closeModalToolMeasure}
        ></ToolMeasure>
      )}
    </div>
  );
};

export default ProFinished;
